<template>
  <div style="background: #f5f5f5;color: #000;height: 100%;">
    <!-- <van-nav-bar title="我的拓客收入" left-arrow :fixed="true" :z-index="999" @click-left="back" /> -->
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item @change="qiehuan" v-model="value1" :options="option1" />
      <div class="fanhui_lj" @click.stop="back">
        <van-icon name="arrow-left" />
      </div>
    </van-dropdown-menu>
    <div class="heds">
      <div v-if="value1 == 0">
        <div class="hed" v-if="isok">
          <div>
            <span style="vertical-align: middle" @click="sjshow = true">{{shijian}}</span>
            <van-icon style="vertical-align: middle" name="arrow-down" />
          </div>
          <div class="sjname">
            <span style="vertical-align: middle" @click="shangjiashow = true">{{sjform.garageName}}</span>
            <van-icon style="vertical-align: middle" name="arrow-down" />
          </div>
          <div class="sjname" style="font-size: 20px;font-weight: bold;">
            <span style="vertical-align: middle">￥{{total?total.toFixed(2):'0.00'}}</span>
          </div>
          <div class="ris">
            <!-- <img class="t_img" @click="totuijian" src="../../assets/img/xiaxian.png" alt /> -->
            <!-- <img class="t_img" @click="toewm" src="../../assets/img/t_gzh.png" alt /> -->
            <!-- <img
              @click="toshenqin"
              style="margin-right: 13px;"
              class="tkimg"
              src="../../assets/img/tk_shenqin.png"
              alt
            />-->
            <img @click="totuijian" class="tkimg" src="../../assets/img/tk_jilv.png" alt />
          </div>
        </div>
        <div class="hed" v-else>
          <div class="weisq">您暂时未申请拓客业务</div>
          <!-- <div class="shenqin" @click="toshenqin">去申请</div> -->
        </div>
      </div>
      <div v-if="value1 == 1">
        <div class="hed">
          <div>
            <span style="vertical-align: middle" @click="sjshow = true">{{shijian}}</span>
            <van-icon style="vertical-align: middle" name="arrow-down" />
          </div>
          <div class="sjname">
            <span style="vertical-align: middle" @click="shangjiashow = true">{{sjform.garageName}}</span>
            <van-icon style="vertical-align: middle" name="arrow-down" />
          </div>
          <div class="sjname" style="font-size: 20px;font-weight: bold;">
            <span style="vertical-align: middle">￥{{total?total.toFixed(2):'0.00'}}</span>
          </div>
          <div class="ris">
            <!-- <img
              @click="toshenqin"
              style="margin-right: 13px;"
              class="tkimg"
              src="../../assets/img/tk_shenqin.png"
              alt
            />
            <img @click="tojv" class="tkimg" src="../../assets/img/tk_jilv.png" alt />-->
          </div>
        </div>
      </div>
    </div>
    <div style="padding-top:200px;background: #f5f5f5;">
      <div v-if="value1 == 0">
        <van-empty v-if="listst.length == 0" description="暂无数据~" />
        <van-cell-group
          class="tk_lis"
          v-for="(item,idx) in listst"
          :key="idx"
          @click="gotoxq(item,0)"
        >
          <van-cell
            style="border-radius: 10px;"
            title="车牌"
            value-class="jiyou"
            :value="item.carNo"
          />
          <van-cell title="修理厂名称" value-class="jiyou" :value="item.garageName" />
          <van-cell title="结算日期" value-class="jiyou" :value="item.settlementDate" />
          <van-cell
            v-if="item.type == 0"
            style="border-radius: 10px;"
            title="提成金额"
            :value="'￥'+item.zong?item.zong.toFixed(2):'0.00'"
            value-class="jired"
          />
          <van-cell
            v-if="item.type == 1"
            style="border-radius: 10px;"
            title="提成金额"
            :value="'￥'+item.redAmount?item.redAmount.toFixed(2):'0.00'"
            value-class="jired"
          />
        </van-cell-group>
      </div>
      <div v-if="value1 == 1">
        <van-empty v-if="listform.length == 0" description="暂无数据~" />
        <van-cell-group
          class="tk_lis"
          v-for="(item,idx) in listform"
          :key="idx"
          @click="gotoxq(item,1)"
        >
          <van-cell
            style="border-radius: 10px;"
            title="链接名称"
            value-class="jiyou"
            :value="item.shareName"
          />
          <van-cell title="修理厂名称" value-class="jiyou" :value="item.garageName" />
          <van-cell title="结算日期" value-class="jiyou" :value="item.settlementDate" />
          <van-cell
            style="border-radius: 10px;"
            title="提成金额"
            :value="'￥'+item.redAmount?item.redAmount.toFixed(2):'0.00'"
            value-class="jired"
          />
        </van-cell-group>
      </div>
    </div>
    <van-popup v-model="sjshow" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        @cancel="sjshow =false"
        @confirm="sjqueding"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
      />
    </van-popup>
    <van-popup v-model="shangjiashow" position="bottom" :style="{ height: '40%' }">
      <van-picker
        title="选择修理厂"
        show-toolbar
        :columns="list"
        value-key="garageName"
        @confirm="onConfirm"
        @cancel="shangjiashow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import userwei from "../../api/user";

export default {
  components: {},
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2028, 10, 1),
      currentDate: new Date(),
      sjshow: false,
      option1: [
        { text: "引荐分红收入", value: 0 },
        { text: "链接分红收入", value: 1 }
      ],
      listform: [],
      shangjiashow: false,
      isok: true,
      value1: 0,
      startTime: "",
      shijian: "",
      sjform: {},
      list: [],
      total: 0,
      zong: null,
      listst: []
    };
  },
  methods: {
    //切换
    qiehuan(e) {
      console.log(e);
      if (e == 0) {
        this.onLoad();
      } else {
        this.lianjielist();
      }
    },
    //选择修理厂
    onConfirm(value, index) {
      this.listst = [];
      this.sjform = value;
      this.srlist();
      this.shangjiashow = false;
      // this.console.log(value);
    },
    onLoad() {
      this.yinjianlist();
    },
    //链接分红
    lianjielist() {
      userwei.lianjiegarageList({ uid: this.userInfo.id }).then(res => {
        this.list = res.data;
        var lis = {
          gid: 0,
          garageName: "全部汽修厂",
          customerId: 0
        };
        this.list.unshift(lis);
        this.sjform = lis;
        this.ljlist();
      });
    },
    //引荐分红
    yinjianlist() {
      userwei.garageList({ customerId: this.userInfo.id }).then(res => {
        console.log(this.currentDate);
        this.list = res.data;
        if (res.data.length > 0) {
          var lis = {
            gid: 0,
            garageName: "全部汽修厂",
            customerId: 0
          };
          this.list.unshift(lis);
          this.sjform = lis;
          this.srlist();
        } else {
          this.isok = false;
        }
      });
    },
    //链接分红列表
    ljlist() {
      this.total = 0;
      userwei
        .report({
          uid: this.userInfo.id,
          gid: this.sjform.gid,
          startTime: this.startTime
        })
        .then(res => {
          // console.log(res);
          this.listform = res.data;
        });
    },
    //引荐分红列表
    srlist() {
      this.total = 0;
      userwei
        .inCome({
          customerId: this.userInfo.id,
          gid: this.sjform.gid,
          startTime: this.startTime
        })
        .then(res => {
          // console.log(res);
          this.listst = res.data;

          this.listst.forEach((itemd, index) => {
            var zonger = null;
            var zonger2 = null;
            var heji = 0;
            var heji2 = 0;
            // console.log(itemd);
            var TCpeijian = itemd.tokerPercentageDTO;
            //工时提成
            var percentageTimeRate =
              itemd.tokerPercentageDTO.percentageTimeRate;
            //配件提成
            var percentagePartRate =
              itemd.tokerPercentageDTO.percentagePartRate;
            itemd.orderSettlementedDetailsVOS.orderItemList.forEach(
              (item, index) => {
                zonger += parseFloat(item.standPrice * item.counts);
                heji += parseFloat(
                  item.standPrice * item.counts * (percentageTimeRate / 100)
                );

                item.orderPartList.forEach((items, indexs) => {
                  if (TCpeijian.tokerPartPercentageDOS.length < 1) {
                    items.ticheng = TCpeijian.percentagePartRate;
                  } else {
                    TCpeijian.tokerPartPercentageDOS.forEach((i, indexs) => {
                      if (i.partName == items.partName) {
                        items.ticheng = i.percentage;
                      } else {
                        items.ticheng = TCpeijian.percentagePartRate;
                      }
                    });
                  }
                  zonger2 += parseFloat(items.price * items.counts);
                  heji2 += parseFloat(
                    items.price * items.counts * (items.ticheng / 100)
                  );
                });
              }
            );
            if (itemd.type == 1) {
              itemd.zong = itemd.redAmount;
            } else {
              if (itemd.orderSettlementedDetailsVOS.amountDiscount) {
                itemd.zong =
                  heji2 * 1 + heji * 1 - this.zhekou.amountDiscount * 0.15;
              } else {
                itemd.zong = heji2 * 1 + heji * 1;
              }
            }
            // console.log(heji);
            // itemd.zong = heji2 * 1 + heji * 1;
            this.total += itemd.zong;
          });
        });
    },
    //分红详情
    gotoxq(item, idx) {
      console.log(item);
      if (idx == 0) {
        if (item.type == 0) {
          this.$router.push({
            name: "tuoke_detail",
            params: {
              list: item,
              idx: idx
            }
          });
        }
      } else {
        this.$router.push({
          name: "tuoke_detail",
          params: {
            list: item,
            idx: idx
          }
        });
      }
    },
    //跳转我推荐的客户列表
    totuijian() {
      this.$router.push({
        name: "tuoke_recommend"
      });
    },
    //跳转二维码
    toewm() {
      this.$router.push({
        name: "tuoke_ma"
      });
    },
    //确定选中时间
    sjqueding(value) {
      let sday = this.formatTime(value, "yyyy-MM");
      let sday2 = this.timeFormat(value);
      this.startTime = sday;
      this.shijian = sday2;
      this.srlist();
      this.sjshow = false;
      console.log(sday2);
    },
    // 当前时间
    addDate() {
      let nowDate = new Date();
      let date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      };
      if (date.month < 10) {
        date.month = "0" + date.month;
      }
      this.startTime = date.year + "-" + date.month;
      this.shijian = this.timeFormat(nowDate);
      console.log(this.startTime);
    },
    timeFormat(time) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      return year + "年" + month + "月";
    },
    formatTime: function(date, fmt) {
      var date = new Date(date);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      var o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          var str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : ("00" + str).substr(str.length)
          );
        }
      }
      return fmt;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    toshenqin() {
      this.$router.push({
        path: "tuoke_apply"
      });
    },
    tojv() {
      this.$router.push({
        path: "tuoke_record"
      });
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.addDate();
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
.views {
  /deep/ div:nth-of-type(2) span {
    color: #1989fa;
  }
}
.van-dropdown-menu {
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
}
.fanhui_lj {
  position: absolute;
  left: 0;
  top: 0;
  // margin-top: 14px;
  line-height: 50px;
  font-size: 18px;
  width: 40px;
  text-align: center;
  height: 48px;
}
.heds {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 48px;
  z-index: 9;
}
.hed {
  margin: 0 10px;
  height: 140px;
  border-radius: 10px;
  background: #2aad67;
  font-size: 12px;
  color: #fff;
  padding: 5px 12px;
}
.tkimg {
  width: 30px;
  margin-right: 5px;
}
.t_img {
  width: 28px;
  float: left;
  margin-right: 15px;
}
.tk_lis {
  margin: 0 10px;
  margin-top: 6px;
  border-radius: 5px;
  padding: 8px 0;
}
.sjname {
  text-align: center;
  font-size: 13px;
  margin-top: 5px;
}
.jired {
  color: red;
}
.jiyou {
  color: #666;
}
.weisq {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
}
.ris {
  text-align: right;
  margin-top: 10px;
}
.shenqin {
  width: 80px;
  background: #fff;
  margin: auto;
  margin-top: 25px;
  color: rgb(26, 226, 126);
  font-size: 15px;
  text-align: center;
  border-radius: 6px;
}
.van-cell {
  color: #323233;
  font-size: 14px;
}
</style>
